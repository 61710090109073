import React from "react";
import ItemCard from "../../../components/industry4.0.component/ItemCard";
import MediaCard from "../../../components/industry4.0.component/card";


const Industrytec = () => {
  return (
      <div>
        {/* <ItemCard /> */}
        <MediaCard />
      </div>
  );
};

export default Industrytec;