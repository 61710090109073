export const dataindustry = [
    {
        id :1,
        title: "Additive Manufacturing (3D Printing)",
        link: "https://www.greenbiz.com/sites/default/files/images/articles/featured/3dprintingasharkyusstock.png",
        summry: "Additive manufacturing allows the creation of complex three-dimensional objects by layering materials. It offers flexibility, customization, and reduced waste compared to traditional manufacturing methods."
    },
    {
        id :2,
        title: "Cloud computing",
        link: "https://daily.jstor.org/wp-content/uploads/2015/07/syntheticbrain.jpg",
        summry: "Cloud computing provides scalable and on-demand access to computing resources, enabling storage, processing, and analysis of large datasets. It facilitates collaboration, data sharing, and remote access to industrial systems and services."
    },
    {
        id :3,
        title: "Augmented Reality (AR) and Virtual Reality (VR)",
        link: "https://cdn.skoda-storyboard.com/2021/05/210510_augmented-reality-1.jpg",
        summry: "AR and VR technologies enhance human-machine interactions and provide immersive experiences. They find applications in training, maintenance, and simulation of industrial processes."
    },
    {
        id :4,
        title: "Internet of Things (IoT)",
        link: "https://industrywired.com/wp-content/uploads/2020/10/IIOT.png",
        summry: " IoT enables the connection of physical devices and objects to the internet, allowing them to collect and exchange data. This connectivity enables real-time monitoring, remote control, and optimization of industrial processes."
    },
    {
        id :5,
        title:"Robotics and Automation",
        link: "https://daily.jstor.org/wp-content/uploads/2015/07/syntheticbrain.jpg",
        summry: "Advanced robotics systems and automation technologies enable the automation of repetitive tasks, enhancing productivity and efficiency. Collaborative robots (cobots) can work alongside humans, assisting with complex or physically demanding tasks."
    },
    {
        id :6,
        title: "Cyber-Physical Systems (CPS)",
        link: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2G-Ilmkrl183WlaZuEmOpRv9rxozrpl-_wQ&usqp=CAU",
        summry: "CPS refers to the integration of physical components with digital systems. It involves sensors, actuators, and networks that monitor and control physical processes. CPS enables real-time data collection, analysis, and feedback for optimized operation."
    },
    {
        id :7,
        title: "Big Data and Analytics",
        link: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Top_9_Cybersecurity_Tools.jpg",
        summry: "With the increasing volume of data generated by connected devices and sensors, big data analytics plays a crucial role in extracting meaningful insights. Analyzing large datasets can help businesses make informed decisions, optimize operations, and improve efficiency."
    },
    {
        id :8,
        title: "Artificial Intelligence (AI) and Machine Learning (ML)",
        link: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Data-Science-vs.-Big-Data-vs.jpg",
        summry: " AI and ML technologies enable machines to learn, reason, and make decisions. They can be applied in various industrial scenarios, such as predictive maintenance, quality control, and autonomous systems."
    },
];