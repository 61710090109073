import React from "react";
import ContactForm from "../../../components/contactus.compoents/cont";

const contact = () => {
  return (
    <div className="container mt-10">
      <ContactForm />
    </div>
  );
};

export default contact;
