export const fablab = [
  {
    src: "https://new-bucket13.s3.ap-southeast-1.amazonaws.com/FabLab_images/IMG_7176.JPG",
    w: 700,
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>Boats (Jeshu John - designerspics.com)</div>
    //   </div>
    // ),
  },
  {
    src: "https://new-bucket13.s3.ap-southeast-1.amazonaws.com/FabLab_images/IMG-20220924-WA0029.jpg",
    w: 700,
  },
  {
    src: "https://new-bucket13.s3.ap-southeast-1.amazonaws.com/FabLab_images/IMG-20220925-WA0048.jpg",
    w: 700,
  },
  {
    src: "https://new-bucket13.s3.ap-southeast-1.amazonaws.com/FabLab_images/IMG_0414.jpg",
    w: 700,
  },
];
