export const FabLabMaknadura = [
  // {
  //   src: "https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/image-slider/IMG_9796-1.jpg",
  //   width: 700,
  //   height: 400,
  //   customOverlay: (
  //     <div className="custom-overlay__caption">
  //       <div>Boats (Jeshu John - designerspics.com)</div>
  //     </div>
  //   ),
  // },
  // {
  //   src: "https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/image-slider/IMG_9720-1.jpg",
  //   width: 700,
  //   height: 400,
  // },
];
