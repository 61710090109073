import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useStateContext } from "../../context/ContextProvider";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../config/index";
import { Amplify } from "aws-amplify";
import { Storage } from "aws-amplify";
import { FaEdit, FaTrash } from "react-icons/fa";

const EventsTable = ({ isAuthenticated }) => {
  const [event, setEvent] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { setLoading } = useStateContext();
  const history = useHistory();
  const [imageUrls, setImageUrls] = useState([]);

  const filteredEvent = event.filter((item) =>
    item.title_pastevent.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (typeof isAuthenticated === "undefined") {
      //
      // Authentication status not yet determined, do nothing
    } else if (!isAuthenticated) {
      // User is not authenticated, redirect to login page
      history.push("/login");
    } else {
      // User is authenticated, do something that takes time
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        identityPoolId: "ap-southeast-1:1bab1487-9e1b-494f-8758-ac6afed9cff4",
        region: "ap-southeast-1",
      },

      Storage: {
        AWSS3: {
          bucket: "new-bucket13",
          region: "ap-southeast-1",
        },
      },
    });
  }, []);

  const csrftoken = getCookie("csrftoken");
  axios.defaults.headers.common["X-CSRFToken"] = csrftoken;

  const getEvent = async () => {
    try {
      // console.log("access", token);
      const response = await axios.get(`${API_URL}/event/`);
      //only status is true data will be shown
      setEvent(response.data); //only status is true data will be shown
      console.log(response.data);
      const urls = await Promise.all(
        response.data.map((curElem) => downloadFile(curElem.image))
      );
      setImageUrls(urls);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFile = async (fileName) => {
    try {
      const fileURL = await Storage.get(fileName);
      return fileURL;
    } catch (error) {
      console.log("Error retrieving file:", error);
      return null;
    }
  };

  useEffect(() => {
    getEvent();
    onChange();
  }, []);

  const onChange = (id, status) => {
    const csrftoken = getCookie("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .patch(
        `${API_URL}/event/${id}/update/`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        alert("Status updated successfully");
        window.location.reload(); // optional - refreshes the page after the update
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = async (id, fileName) => {
    const csrftoken = getCookie("csrftoken");
    try {
      // Delete the image from AWS S3
      // console.log("Delete img:", fileName);
      await Storage.remove(fileName);

      // Delete the data from the database
      const response = await axios.delete(`${API_URL}/event/${id}/delete/`, {
        headers: {
          "X-CSRFToken": csrftoken,
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "Content-Type": "application/json",
        },
      });
      //
      getEvent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto py-10">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 className="text-2xl mb-4">Event</h1>
        <div className="relative w-full mb-4">
          <input
            type="search"
            className="w-full rounded border-gray-300 shadow-sm pl-10 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
            placeholder="Search event by title"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="h-5 w-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M9.444 0C4.229 0 0 4.229 0 9.444c0 5.214 4.229 9.444 9.444 9.444 5.214 0 9.444-4.23 9.444-9.444C18.889 4.229 14.659 0 9.444 0zm4.516 14.614a.63.63 0 01-.903 0l-2.028-2.028a5.327 5.327 0 01-3.364 1.226c-2.932 0-5.308-2.375-5.308-5.308S2.932 2.67 5.864 2.67s5.308 2.375 5.308 5.308a5.294 5.294 0 01-1.226 3.364l2.028 2.028a.637.637 0 010 .903zM5.864 3.934c-1.96 0-3.558 1.597-3.558 3.558s1.597 3.558 3.558 3.558 3.558-1.597 3.558-3.558-1.597-3.558-3.558-3.558z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">#</th>
                <th className="py-3 px-6 text-left">Image</th>
                <th className="py-3 px-6 text-left">Event Title</th>
                <th className="py-3 px-6 text-left">Summary</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {filteredEvent.map((curElem, index) => {
                return (
                  <tr key={curElem.id}>
                    <td className="py-3 px-6">{curElem.id}</td>
                    <td className="py-3 px-6">
                      <img
                        src={imageUrls[index]}
                        alt={curElem.title_pastevent}
                        className="w-16 h-16 rounded-full border-2 border-gray-300"
                      />
                    </td>
                    <td className="py-3 px-6">{curElem.title_pastevent}</td>
                    <td className="py-3 px-6">{curElem.summery_pastevent}</td>
                    <td className="py-3 px-6">
                      <div className="flex items-center">
                        <button
                          className={`bg-${
                            curElem.status ? "red" : "green"
                          }-500 bg-${
                            curElem.status ? "red" : "green"
                          }-700 text-black font-bold py-2 px-4 rounded-full text-sm`}
                          onClick={() => onChange(curElem.id, !curElem.status)}
                        >
                          {curElem.status ? "HIDE" : "SHOW"}
                        </button>
                      </div>
                    </td>

                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <Link
                          to={`/edit-event/${curElem.id}`}
                          className="text-amber-200 hover:text-amber-400 mx-2"
                        >
                          <FaEdit className="text-2xl" />
                        </Link>
                        <button
                          onClick={() =>
                            handleDelete(curElem.id, curElem.image)
                          }
                          className="text-red-400 hover:text-red-600 mx-2"
                        >
                          <FaTrash className="text-2xl" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

function getCookie(name) {
  const cookieValue = document.cookie.match(
    "(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return cookieValue ? cookieValue.pop() : "";
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(EventsTable);
