export const datamaknadurateam = [
    {
        id: 1,
        name: "Kavith Udapola",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/kavith.jpg"
    },
    {
        id: 2,
        name: "Imesh Maduranga",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/imesh1.jpg",
    },
    {
        id: 3,
        name: "Amani Sureshika",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/Amani.jpg",
    },
    {
        id: 4,
        name: "Theekshana Jayanuwan",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/theekshana+(2).JPG",
    },
    {
        id: 5,
        name: "Rashin Manoj",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/manoj.jpg",
    },
    {
        id: 6,
        name: "Ashen Thissera",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/Ashen.jpg",
    },
    {
        id: 7,
        name: "Samitha Sandeepa",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/samitha.png",
    },
    {
        id: 8,
        name: "Asantha Bhuddi",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/asantha.JPG",
    },
    {
        id: 9,
        name: "Dheegara Pathiraja",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/dheegara.jpg",
    },
    {
        id: 10,
        name: "Thulanga Sasmini",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/thulanga.jpg",
    },
    {
        id: 11,
        name: "Chinthaka Sampath",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/chinthaka.jpg",
    },
    {
        id: 12,
        name: "Thimira Thilikarathne",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/thimira.png",
    },
    {
        id: 13,
        name: "Duleendra Udapola",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/duleenra.jpg",
    },
    {
        id: 14,
        name: "sakith thissera",
        image:"https://fablanka-website.s3.ap-southeast-1.amazonaws.com/images/makandura-team/sakith.JPG",
    },
];